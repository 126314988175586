@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/themes/palettes/sportColors';

.announcementContainer {
  padding-top: $content-indent-top;
}

.sportsList {
  @include list-styles-reset();
}

.tournamentsList {
  @include list-styles-reset();
  margin: $content-indent;
  margin-top: 0;
}

.announcementsList {
  padding: 0;

  & .announcementItem:last-child {
    border-bottom: 0;
  }
}

.announcementHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 $content-indent-right 16px $content-indent-left;
}

.announcementTitle {
  @include font-style-h4($font-weight-medium);
  color: var(--text-10);
  margin: 0;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sportsFilter {
  z-index: 21;

  & :global(.dropdown__control) {
    min-width: unset;
  }

  & :global(.dropdown__menu) {
    width: 120px;
    right: 0;
  }
}

.dateFilter {
  z-index: 21;

  & :global(.dropdown__control) {
    min-width: unset;
  }

  & :global(.dropdown__menu) {
    width: 150px;
    right: 0;
  }
}

.announcementItem {
  background-color: var(--shape-10);
  display: flex;
  justify-content: space-between;
  padding: 17px 24px 17px 16px;
  border-bottom: 1px solid var(--shape-50);
}

.announcementLink {
  @include font-style-tiny($font-weight-bold);
  color: var(--text-10);
}

.announcementTime {
  @include font-style-tiny($font-weight-regular);
  color: var(--text-20);
}

@each $sportKey, $color in $sportColors {
  :global(.#{$sportKey}-darken) {
    background-color: darken(saturate($color, 15%), 7%);
  }
}
