@import 'src/design-system/styles/typography.mixins';

.multiSelect {
  & :global(.dropdown__control) {
    min-width: 220px;
    border: 1px solid var(--input-default-border);
    cursor: pointer;
    padding: 5px 8px;
    position: relative;
    background: var(--shape-10);
    transition: none;

    & :global(.dropdown__value-container) {
      @include font-style-small;
      display: block;
      max-width: 166px;
      white-space: nowrap;
      text-overflow: ellipsis;

      & :global(.dropdown__multi-value) {
        display: inline;
        min-width: unset;
        white-space: nowrap;
        background-color: transparent;
      }

      & :global(.dropdown__placeholder) {
        display: inline;
        color: var(--text-10);
      }

      & input {
        position: absolute;
      }
    }

    &:hover {
      border-color: var(--text-20);
    }
  }

  & :global(.dropdown__control--is-focused) {
    border: 1px solid var(--text-link);
    box-shadow: none;

    &:hover {
      border-color: var(--text-link);
    }
  }

  & :global(.dropdown__control--is-disabled) {
    background-color: var(--shape-20);
    border: 1px solid var(--input-default-border);
    cursor: not-allowed;

    & :global(.dropdown__single-value) {
      color: var(--text-30);
    }

    &:hover {
      border-color: var(--input-default-border);
    }
  }

  & :global(.dropdown__single-value) {
    color: var(--text-10);
  }

  & :global(.dropdown__multi-value__label) {
    padding: 0;
    font-size: inherit;
  }

  & :global(.dropdown__value-container .dropdown__multi-value:not(:last-of-type)) {
    margin-right: 3px;

    &::after {
      content: ',';
    }
  }


  & :global(.dropdown__dropdown-indicator) {
    & svg {
      fill: var(--text-30);
    }
  }

  & :global(.dropdown__menu-notice--no-options) {
    @include font-style-tiny;
    padding: 24px 16px;
    box-shadow: var(--shadow-4);
  }

  & :global(.dropdown__menu) {
    box-shadow: var(--shadow-4);
    background: var(--shape-10);
  }

  &.error {
    & :global(.dropdown__control) {
      border: 1px solid var(--system-error);
    }

    & :global(.dropdown__control--is-disabled) {
      border: 1px solid var(--input-default-border);
    }
  }

  & :global(.dropdown__menu-list) {
    padding: 0;
    overflow: hidden;
    border-radius: 4px;

    & :global(.dropdown__option--is-focused) {
      background-color: var(--shape-50);
    }

    & :global(.dropdown__option--is-selected) {
      background-color: transparent;
    }
  }

  & :global(.dropdown__option) {
    @include font-style-small;
    display: block;

    color: var(--text-10);
    padding: 6px 8px;
    cursor: pointer;
  }

  & :global(.dropdown__indicators) {
    display: flex;
    align-items: center;
  }
}

.multiSelectLarge {
  & :global(.dropdown__control) {
    padding: 13px 7px 13px 15px;
  }

  & :global(.dropdown__value-container) {
    @include font-style-subtitle;
  }

  & :global(.dropdown__option) {
    @include font-style-subtitle;
    padding: 13px 15px 13px 16px;
  }
}

.multiSelectMedium {
  & :global(.dropdown__control) {
    padding: 7px 8px 7px 12px;
  }

  & :global(.dropdown__value-container) {
    @include font-style-default;
  }

  & :global(.dropdown__option) {
    @include font-style-default;
    padding: 8px 11px 8px 12px;
  }
}

.multiSelectSmall {
  & :global(.dropdown__control) {
    padding: 3px 8px;
  }

  & :global(.dropdown__option) {
    padding: 4px 8px;
  }
}
