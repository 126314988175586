@import 'src/design-system/styles/typography.mixins';

.optionContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;

  & span {
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.optionCheckbox {
  pointer-events: none;
}

.selected {
  font-weight: bold;
}

.scrollContainer {
  & :global(.simplebar-content-wrapper .simplebar-content) {
    min-height: auto;
  }
}

.dropdownIndicator {
  transition: transform .2s linear, easy-in .2s linear;
  transform: rotate(0deg);
  color: var(--text-40);

  &:hover,
  &:focus {
    opacity: .8;
  }

  &.rotated {
    transform: rotate(-180deg);
  }
}

.divider {
  border: 1px solid var(--shape-50);
  transform: rotate(-90deg);
  width: 14px;
}

.clearIndicatorIcon {
  color: var(--text-40);

  &:hover,
  &:focus {
    opacity: .8;
  }
}
